body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
    'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f2f2f2;

  --primary-color: #4393ca;
  --accent-color: #4394cab0;
  --text-color: #444444;
}

.flex-container {
  display: flex;
}

.ccp-col {
  /* width: 334px !important; */
}

.logo-container {
  height: 100% !important;
  padding-top: 5px;
  padding-bottom: 5px;
}

.screenpop-col {
  flex: 1 !important;
  padding: 0 !important;
}

.transparent {
  color: #f4f6f8;
  opacity: 0.2;
}

.ui.vertical.menu {
  width: unset;
}

.ccp-wrapper {
  /* border-style: solid; */
  /* border-color: grey; */
  /* border-width: 1px; */
  /* padding: 1px; */
  /* display: inline-block; */
  /* width: 334px !important; */
  padding-right: 0 !important;
}
.collapsed {
  width: 1px !important;
  height: 1px !important;
}

.ccp-frame {
  height: 512px;
}

.ccp-frame.collapsed {
  padding-top: 15px;
}

.ccp-frame iframe,
.screenpop-col iframe {
  margin-left: auto;
  margin-right: auto;
  border: 0;
}

.ccp-bottom-panel {
  padding-left: 15px;
  padding-right: 15px;
  padding-bottom: 10px;
}

.center {
  text-align: center;
}

.error {
  color: #721c24;
  background-color: #f8d7da;
  border-color: #f5c6cb;
  border-style: solid;
  padding: 8px;
}

.ui.button {
  border-radius: 0;
}

CallInfo .CallInfo,
secure-payment .payment,
CallInfo .call-list {
  width: 200px;
  border: 1px solid silver;
  border-radius: 12px;
  background: white;
  vertical-align: top;
  margin-left: 12px;
  text-align: center;
  padding: 0px;
}

.CallInfo,
.payment,
.call-list {
  width: 200px;
  border: 1px solid silver;
  /*border-radius: 12px;*/
  background: white;
  vertical-align: top;
  margin-left: 12px;
  text-align: center;
  padding: 0px;
}

.card-jmb {
  background-color: #333;
  width: 100% !important;
}

.card-jmb.ui.card > .content.header {
  padding: 0.9em;
}
.card-jmb.ui.card > .content.body {
  height: 250px;
  overflow: auto;
}

.card-jmb.ui.card > .content.header {
  background-color: var(--primary-color);
}

.title .header {
  color: var(--text-color) !important;
  width: 100% !important;
}

.title {
  color: var(--text-color) !important;
  width: 100% !important;
}

.card-jmb.ui.card > .content > .header {
  font-family: AmazonEmber, Helvetica, sans-serif;
  font-size: 0.875rem;
}
.phone-dir-icon {
  font-size: 20px !important;
}
.button {
  background-color: #0161a9 !important;
  color: #dad419;
}

.call-icon {
  margin-top: 9px !important;
}
.call-icon:hover {
  cursor: pointer;
}

#headerTabs .ui.menu {
  background-color: #117e26;
  margin-bottom: 0;
  float: right;
  margin-right: 6px;
}
#headerTabs .ui.attached {
  border: 0;
  padding: 0;
  margin-top: 10px;
}
#headerTabs .ui.menu .active.item,
#headerTabs .ui.menu .active.item:hover {
  background-color: #fff !important;
  color: #117e26 !important;
  border-left: 0;
  border-radius: 8px 8px 0px 0px;
}

#headerTabs .ui.menu .item,
#headerTabs .ui.menu .item:hover {
  background-color: #117e26;
  color: #fff;
  padding: 0.3125rem 0.625rem;
  margin: 0 3px;
  border-left: 1px solid rgba(255, 255, 255, 0.18);
  border-radius: 0px 0px 0px 0px;
  margin: 0;
  min-width: 40px;
}
#headerTabs .ui.menu .item .cornerLeft {
  position: absolute;
  width: 6px;
  height: 6px;
  bottom: 0;
  background-color: inherit;
  right: 100%;
  overflow: hidden;
  z-index: -1;
}
#headerTabs .ui.menu .item .cornerLeft::after {
  content: '';
  position: absolute;
  top: 0px;
  height: 6px;
  width: 6px;
  box-shadow: 0 0 0 6px var(--bg-color);
  background-color: #117e26;
  left: 0px;
  border-radius: 0px 0px 6px;
}
#headerTabs .ui.menu .active.item .cornerLeft {
  z-index: 2;
}
#headerTabs .ui.menu .active.item .cornerRight {
  z-index: 2;
}
#headerTabs .ui.menu .item .cornerRight {
  position: absolute;
  width: 6px;
  height: 6px;
  bottom: 0;
  left: 100%;
  overflow: hidden;
  background-color: inherit;
  z-index: -1;
}
#headerTabs .ui.menu .item .cornerRight::after {
  right: 0px;
  border-radius: 0px 0px 0px 6px;
  content: '';
  position: absolute;
  top: 0px;
  height: 6px;
  width: 6px;
  box-shadow: 0 0 0 6px var(--bg-color);
  background-color: #117e26;
}
#headerTabs .ui.menu .item::before {
  width: 0;
}
#headerTabs .segment {
  width: 100%;
  margin: 0;
  border: none;
  max-height: 290px;
  overflow: auto;
}

#headerTabsGeneric .ui.menu {
  background-color: #181818;
  margin-bottom: 0;
  float: right;
  margin-right: 6px;
}
#headerTabsGeneric .ui.attached {
  border: 0;
  padding: 0;
  margin-top: 10px;
}
#headerTabsGeneric .ui.menu .active.item,
#headerTabsGeneric .ui.menu .active.item:hover {
  background-color: #fff !important;
  color: #181818 !important;
  border-left: 0;
  border-radius: 8px 8px 0px 0px;
}

#headerTabsGeneric .ui.menu .item,
#headerTabsGeneric .ui.menu .item:hover {
  background-color: #4a4a4a;
  color: #fff;
  padding: 0.3125rem 0.625rem;
  margin: 0 3px;
  border-left: 1px solid rgba(255, 255, 255, 0.18);
  border-radius: 0px 0px 0px 0px;
  margin: 0;
  min-width: 40px;
}
#headerTabsGeneric .ui.menu .item .cornerLeft {
  position: absolute;
  width: 6px;
  height: 6px;
  bottom: 0;
  background-color: inherit;
  right: 100%;
  overflow: hidden;
  z-index: -1;
}
#headerTabsGeneric .ui.menu .item .cornerLeft::after {
  content: '';
  position: absolute;
  top: 0px;
  height: 6px;
  width: 6px;
  box-shadow: 0 0 0 6px var(--bg-color);
  background-color: #4a4a4a;
  left: 0px;
  border-radius: 0px 0px 6px;
}
#headerTabsGeneric .ui.menu .active.item .cornerLeft {
  z-index: 2;
}
#headerTabsGeneric .ui.menu .active.item .cornerRight {
  z-index: 2;
}
#headerTabsGeneric .ui.menu .item .cornerRight {
  position: absolute;
  width: 6px;
  height: 6px;
  bottom: 0;
  left: 100%;
  overflow: hidden;
  background-color: inherit;
  z-index: -1;
}
#headerTabsGeneric .ui.menu .item .cornerRight::after {
  right: 0px;
  border-radius: 0px 0px 0px 6px;
  content: '';
  position: absolute;
  top: 0px;
  height: 6px;
  width: 6px;
  box-shadow: 0 0 0 6px var(--bg-color);
  background-color: #4a4a4a;
}
#headerTabsGeneric .ui.menu .item::before {
  width: 0;
}
#headerTabsGeneric .segment {
  width: 100%;
  margin: 0;
  border: none;
  max-height: 450px;
  overflow: auto;
}

#tabsCard {
  background-color: #117e26;
}
#tabsCard .tabsTitle {
  position: absolute;
  color: #fff;
  font-weight: 600;
  padding: 10px;
}

#tabsCard .tabsTitle {
  position: absolute;
  color: #fff;
  font-weight: 600;
  padding: 10px;
}

#tabsCard .content {
  padding: 0;
}

#tabsCardGeneric {
  background-color: #4a4a4a;
}
#tabsCardGeneric .tabsTitle {
  position: absolute;
  color: #fff;
  font-weight: 600;
  padding: 10px;
}

#tabsCardGeneric .tabsTitle {
  position: absolute;
  color: #fff;
  font-weight: 600;
  padding: 10px;
}

#tabsCardGeneric .content {
  padding: 0;
}

.ui.feed {
  border-bottom: 1px solid rgba(34, 36, 38, 0.1);
  font-size: 12px;
}
.ui.label {
  margin: 0 0 0 10px !important;
}
.ui.feed .meta {
  margin: 0 !important;
}
.location-icon {
  float: right;
  color: #117e26;
  margin-top: -20px !important;
}
.location-icon:hover {
  cursor: pointer;
}
.card.title > .content {
  padding: 0.6em;
}
.card.title > .content .ui.header {
  margin: 0;
}
#dispositionAccordion {
  background: #fff !important;
  float: left !important;
  margin: 0 !important;
  padding: 5px;
  max-height: 220px;
  overflow: auto;
}
#dispositionAccordion .item {
  background: #fff !important;
}
.dispositionButton {
  float: right;
  margin-top: 5px !important;
}
.loader {
  margin-top: 8px !important;
}
#ccpPanel .card {
  width: 100%;
}
.row {
  /*padding: 0!important;*/
}

.callIconBox {
  position: relative;
}
.callIconBox .callTypeIcon {
  font-size: 1em !important;
  margin: 0;
  padding: 0 !important;
  top: 20px;
  right: -10px;
  position: absolute;
}
.dispositonRow {
  margin-top: 20px !important;
}
.callerIcons {
  /*margin-top: 50px;*/
  padding-left: 15px !important;
}
.callerIcons button {
  margin: 5px !important;
}
.callerIcons .ui.basic.label {
  border: none;
  font-size: 10px;
  margin: 0px !important;
  padding: 0;
}

#outbound-call-options .phoneInputRow {
  margin-top: 15px;
  margin-bottom: 15px;
  width: 100%;
}
#outbound-call-options .action-button {
  margin-top: 15px;
  margin-bottom: 15px;
  width: 100%;
  background-color: #0161a9;
  border-radius: 5px;
  color: white;
  text-emphasis: bold;
  text-align: center;
}
#outbound-call-options .action-button:hover {
  background-color: #024471 !important;
}
#outbound-call-options .action-button.calling {
  background-color: rgb(2, 120, 2) !important;
}
#outbound-call-options .action-button > Icon {
  margin-right: 10px;
}
#disposition-list .action-button {
  margin-top: 15px;
  margin-bottom: 15px;
  width: 100%;
  background-color: #0161a9;
  border-radius: 5px;
  color: white;
  text-emphasis: bold;
  text-align: center;
}
#disposition-list .action-button:hover {
  background-color: #024471 !important;
}
#disposition-list .action-button.calling {
  background-color: rgb(2, 120, 2) !important;
}
#disposition-list .action-button > Icon {
  margin-right: 10px;
}
.mb-5 {
  margin-bottom: 10px;
}
.hide-scrollbar::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.hide-scrollbar {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
